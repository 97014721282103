/*Base Styles*/

html {
  height: 100%;
}

body {
  font: {
    family: $font-family-base;
    display: swap;
    size: $font-size-base;
    weight: 400;
  }

  background-color: $body-bg;
  height: 100%;
}

.text-transform-none {
  text-transform: none !important;
}

.br-break {
  word-break: break-all !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.border-bottom {
  border-bottom: solid 1px $gray-light;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.height-100 {
  height: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.height-200 {
  height: 200px !important;
}

.max-width-100 {
  max-width: 100px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.invoice-status-min-width {
  min-width: 150px !important;
}

.invoice-amount-min-width {
  min-width: 130px !important;
}

.datepicker-container-min-max-width {
  min-width: 137px !important;
  max-width: 137px !important;
}

.max-height-120 {
  max-height: 120px !important;
}

.letter-spacing-base {
  letter-spacing: $letter-spacing-base;
}

.letter-spacing-lg {
  letter-spacing: $letter-spacing-lg;
}

.letter-spacing-xl {
  letter-spacing: $letter-spacing-xl;
}

.sub-heading {
  font-size: $font-size-base;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 16px !important;
  }
}

.manage-margin {
  margin-bottom: 0;

  &>* {
    margin-bottom: 6px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

//Shadow
.no-shadow {
  @include box-shadow(none !important);
}

.shadow-sm {
  @include box-shadow($shadow-sm !important);
}

.shadow {
  @include box-shadow($shadow);
}

.shadow-lg {
  @include box-shadow($shadow-lg);
}

.shadow-xl {
  @include box-shadow($shadow-xl);
}

.avatar-shadow {
  @include box-shadow($avatar-shadow);
}

.remove-table-border {

  td,
  th {
    border: 0 none;
  }
}

.pointer {
  cursor: pointer;
}

.font-size-22 {
  font-size: 22px !important;
}

.link-direction {
  font-size: $font-size-normal;
  cursor: pointer;
  text-decoration: underline;
  color: $text-color;

  &:hover {
    color: $text-color;
  }
}

.text-decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.customAutosuggest {
  width: 100% !important;
  height: unset !important;
  margin: 10px 0;
}

.pac-container {
  z-index: 1300;
  border-radius: 5px;
  padding: 0 10px 5px;
}

.input-field {
  float: left;
  width: 100%;
  position: relative;

  .error-message {
    margin-bottom: 0;
  }
}

.dialog-content+div {
  display: none;
}

.input-field {
  .select-field {
    margin-bottom: 10px;
  }

  .input-field-label {
    color: $label-color;
    font-size: $font-size-label;
  }

  &.user-profile-address-picker {
    .address-picker-toggler {
      right: 12px;
    }
  }
}

.text-overflow-ellipsis input {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 120px !important;
}

.underline {
  text-decoration: underline !important;
}

.on-hover-underline:hover {
  text-decoration: underline !important;
}

input:disabled,
textarea:disabled {
  cursor: not-allowed;
}

img.replacement-machine-image {
  width: 100px;
  border-radius: 50px;
}

img.replacement-image {
  width: 30px;
  border-radius: 15px;
}

span.icon-img-height img {
  height: 24px;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer-event-none {
  pointer-events: none;
}

.damage-info-row {
  height: auto !important;
  border-bottom: 1px solid $table-border;

  .damage-info-cell {
    width: 50%;
    padding: 4px 5px !important;
    border: 0;
  }
}

.damage-info-cell_left {
  @extend .damage-info-cell;
  float: left;
}

.damage-info-cell_right {
  @extend .damage-info-cell;
  float: right;
  text-align: right !important;
}

.info-modal-button {
  justify-content: flex-end !important;
  padding: 0 40px 20px 0;
}

.damage-header-info {
  h6 {
    margin-bottom: 0px;
    font-size: $h4-font-size;
  }

  p {
    color: $gray-light;
  }
}

@media screen and (max-width: 1200px) {
  .overflow-scroll-md {
    overflow: scroll;
  }
}

.min-width-1200 {
  min-width: 1200px;
}

.dispotion-filters {
  .select-field {
    height: 42px !important;
  }
}

.custom-tab {
  font-size: $font-size-base !important;
}

.google-map-tooltip {
  font-family: $font-family-base;

  a {
    color: $text-color;
    text-decoration: underline;
  }

  h3 {
    font-size: $h6-font-size;
    font-weight: bold;
  }

  &>span {
    margin-bottom: 5px;
    display: block;
  }
}

.center-content {
  @include flex-center();
}

.cursor-pointer {
  cursor: pointer;
}

.rental-mini-title {
  span {
    font-size: $h5-font-size;
    color: $gray-light;
    font-weight: bold;
  }
}

.rental-add-project {
  position: absolute;
  left: 0;
  bottom: 5px;

  @media screen and (max-width: 767px) {
    bottom: 20px;
  }
}

.machine-modal-custom-style.custom-modal {
  .customer-modal-content-style {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .modal-button-wrapper {
    padding-top: 5px;
  }
}

.overflow-auto {
  overflow: auto;
}

.placeholder-label {
  font-size: $font-size-label;
  color: $label-color;
}

.cell-border-top {
  border-top: 1px solid $table-border;
}

.mw-90 {
  max-width: 90px;
}

.min-width-px-120 {
  min-width: 120px;
}

.max-width-px-200 {
  max-width: 200px;
}

.max-width-px-248 {
  max-width: 248px;
}

.helper-text {
  position: absolute;
  bottom: -20px;
  white-space: nowrap;
}

.custom-helper-text {
  color: #f44336;
  font-size: $font-size-xs;
  margin-top: 8px;
  float: left;
}

.pricing-information-wrapper {
  .pricing-informations {
    padding: 10px 30px 20px 0;

    p {
      min-width: 200px;
      margin: 0;
      padding: 5px 0;

      span.amount {
        float: right;
      }

      &.total-amount {
        font-weight: bold;
        border-bottom: 2px solid $text-color;
        border-top: 2px solid $text-color;
      }
    }
  }
}

.min-height-inherit {
  min-height: inherit;
}

.damage-image-height {
  height: 70px;
}

.progress-bar-wrapper {
  position: relative;

  p {
    color: $white !important;
    height: 100%;
    z-index: 1;
    position: absolute;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
    font-size: $font-size-lg !important;
    min-width: fit-content;

    &.left-side {
      background-color: $green-color;
    }

    &.right-side {
      right: 0;
      background-color: #c4cacc;
    }
  }

  .custom-progress-bar {
    height: 60px !important;

    &>div {
      background-color: $green-color;
    }
  }
}

.responsive-image {
  width: 100%;
  max-height: 90vh;
}

.shadow-none {
  box-shadow: unset !important;
}

.breadcrumb {
  padding: 0px 5px 0px 20px;
}

.new-project-indicator {
  position: absolute;
  padding: 3px 5px;
  color: $text-color;
  font-size: $font-size-xs;
  right: 0;
  top: 38px;
  background-color: $white;
}

@media screen and (min-width: 520px) {
  .quick-menu.quick-action-menu {
    .bg-deep-orange:hover {
      background-color: #fd6565 !important;
    }

    .dropdown-menu {
      right: unset !important;
      left: -175px !important;

      &::before {
        display: none;
      }

      &::after {
        right: 149px;
      }
    }
  }
}

@media (max-width: 450px) {
  .mr-0-under-xs {
    margin: 0 !important;
  }
}

.text-small {
  line-height: $small-text-line-height;
  font-size: $font-size-xs;
}

.text-medium {
  line-height: $small-text-line-height;
  font-size: $font-size-sm;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-large {
  font-size: $font-size-lg;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.text-bold {
  font-weight: bold;
}

.mt--5 {
  margin-top: -5px;
}

.mt--45 {
  margin-top: -45px;
}

.custom-line-chart {
  svg {
    path {
      stroke: $primary;
    }
  }
}

.product-question,
.custom-text-field-label {
  font-size: 0.75rem;
  font-weight: 400;
  color: $gray-light;
}

.question-max-width {
  margin-top: -5px;

  div {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}

.placeholder-animation {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.multi-select-menu {
  max-height: calc(100vh - 96px);

  ul {
    padding-bottom: 0;
  }
}

.multi-select-apply {
  position: sticky !important;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px !important;
}

.overlay-loader-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);

  &.overlay-loader-wrapper--fixed {
    position: fixed;
  }
}

.contact-circle {
  @include border-radius(50%);
  background-color: $theme-orange;
  color: $white;
  width: 30px;
  height: 30px;
  display: inline-flex;
  cursor: pointer;
}

.max-width-1200 {
  max-width: 1200px;
}

.height-inherit {
  height: inherit;
}

.background-transparent {
  background-color: transparent !important;
}

.text-field-background-color {
  background-color: #f2f2f2 !important;
}

.small-text-field {
  background-color: #f2f2f2;
  border-radius: 0;
  // margin-top: 8px!important;
  margin-bottom: 0px !important;

  input {
    padding: 10px;
    box-sizing: content-box;

    &::placeholder {
      color: $gray-light;
      opacity: 1;
      /* Firefox */
      font-size: $font-size-normal;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $gray-light;
      opacity: 1;
      font-size: $font-size-normal;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $gray-light;
      opacity: 1;
      font-size: $font-size-normal;
    }
  }

  &.mt-0 {
    margin-top: 0px !important;
  }

  &.text-field---has-error {
    input {
      &::placeholder {
        color: $theme-color;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $theme-color;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $theme-color;
      }
    }
  }
}

fieldset {
  border: 0;
}

li {
  list-style: none;
}

.text-gray {
  color: $text-color-gray;

  &>span {
    color: $text-color-gray;
  }
}

.text-pink {
  color: $theme-color;

  &>span {
    color: $theme-color;
  }
}

button:disabled {
  opacity: 0.5;
}

.background-hover:hover {
  background-color: $background-card-gray;
}

.product-image.product-image--max-width img {
  max-width: 150px;
  height: auto;
}

img.sub-item {
  height: 1.6em;
  width: 2.3em;
}

.bold {
  font-weight: bold;
}

.text-blue-theme {
  color: $secondary-light;
}

.text-theme {
  color: $theme-color !important;
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.mt--20 {
  margin-top: -20px;
}

.height-70vh {
  height: 70vh;
}

.min-height-70vh {
  min-height: 70vh;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

@media screen and (max-width: $mobile-device-width-max) {
  .app-wrapper h1 {
    font-size: 22px;
  }

  .font-xs-sm {
    font-size: $font-size-base;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .px-under-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mx-under-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  //margin top on small screens
  .mt-xs-3 {
    margin-top: 1rem !important;
  }

  .pr-xs-0 {
    padding-right: 0 !important;
  }

  .pl-xs-0 {
    padding-left: 0 !important;
  }

  .mb-sm-30 {
    margin-bottom: 30px;
  }

  .m-xs-4 {
    margin: 1.5rem;
  }

  .f-left-on-mobile {
    float: left;
  }

  input {
    font-size: $font-size-base !important;
  }

  .app-wrapper {
    padding: 0;
    margin-top: 20px;
  }

  .faq-container {
    .accordion-header-wrapper {
      padding: 24px;
    }

    h2,
    iframe {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .header-notifications {
    margin-right: 6px;
  }

  .single-faq-modal .customer-modal-content-style {
    height: 82vh;
  }

  // remove these with new grid system
  .project-filter-grid {
    padding: 0 !important;
    padding-right: 0.5rem !important;
  }

  .project-filter-grid--pl {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }

  .full-width-mobile {
    @include full-width();
  }
}

@media screen and (min-width: $desktop-device-width-min) {
  .d-on-mobile {
    display: none !important;
  }

  .single-faq-modal .customer-modal-content-style {
    height: 70vh;
  }
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-disabled {
  color: $disabled-gray;
}

.custom-select-field {
  background-color: $background-card-gray;
  padding: 0px 5px;
  border-radius: 5px;
}

body .custom-down-shift-item {
  height: auto;
  padding: 0;

  &:hover,
  &:focus {
    background: transparent;
  }
}

.form-legend-wrapper {
  border: 1px solid $border-dark-gray;
  padding: 8px 13px;
  border-radius: 4px;

  legend {
    width: auto;
    display: flex;
  }
}

.min-height-40 {
  min-height: 40px;
}

.editable-form-overlay {
  display: none;
}

.non-editable-form {
  position: relative;

  .editable-form-overlay {
    text-align: center;

    .overlay-edit-button {
      font-size: 20px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &:hover {
    .editable-form-overlay {
      @include full-screen-overlay(0, 0);
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 20;
      width: 100%;
      top: 0;
      bottom: 0;
      // height: calc(100% - 12px);
      cursor: pointer;
      @extend .center-content;
    }
  }
}

.text-heading span {
  font-size: $font-size-xxl !important;
  @extend h6;
}

.overflow-visible {
  overflow: visible !important;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.project-details-container {
  .custom-paper {
    margin-top: $small-margin;
  }
}

.text-danger {
  color: $danger;
}

.text-success {
  color: $success;
}

.select-time {
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  background-color: $background-card-gray;
}

.contacts-list-filter-wrapper {
  min-width: 300px;
  padding: 0;

  .select-field {
    margin: 0;
  }
}

.faq-container {
  .accordion-header-wrapper {

    &>div,
    h4 {
      margin-bottom: 0;
    }
  }

  .custom-accordion-wrapper {
    border: 1px solid $gray-150;
  }
}

ul.plain-list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.has-background-loading {
  @extend .placeholder-animation;
}

.table-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: gray;
  opacity: 0.3;
}

.fade-in {
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease 0.2s;
  -moz-animation: fadeIn ease 0.2s;
  -o-animation: fadeIn ease 0.2s;
  -ms-animation: fadeIn ease 0.2s;
}

.fade-in-slow {
  animation: fadeIn ease 0.8s;
  -webkit-animation: fadeIn ease 0.8s;
  -moz-animation: fadeIn ease 0.8s;
  -o-animation: fadeIn ease 0.8s;
  -ms-animation: fadeIn ease 0.8s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.price-list-no-result {
  max-width: 600px;
  display: inline-block;
}

.no-focus-effect:focus {
  outline: none;
}

.print-table {
  display: block;
  max-width: 100%;

  &>tbody {
    display: block;

    &>tr {
      display: block;

      &>td {
        display: block;
      }
    }
  }
}

.bg-danger-light {
  background-color: $danger-light !important;
}

.border-danger-v2 {
  border: 1px solid $danger-v2 !important;
}

.text-danger-v2 {
  color: $danger-v2 !important;
}

.field-disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.primary-icon svg {
  fill: $primary;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // same as text-truncate
}

.text-truncate {
  @include text-truncate();
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .ie11-display-block {
    display: block;
  }
}


.app-logo img {
  height: 27px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.border-dashed {
  border: 1px dashed $gray-light;
}

[hidden] {
  display: none !important;
}